<template>
  <div class="print-product">
    <KTCodePreview v-bind:title="'In sản phẩm'">
      <template v-slot:toolbar>
        <button
          type="button"
          class="btn btn-primary font-weight-bolder btn-sm"
          @click="printData"
          style="width: max-content"
        >
          <i class="fas fa-print"></i>
          In sản phẩm
        </button>
      </template>
      <template v-slot:preview>
        <b-row>
          <b-col md="2">
            <label>Bạn cần in:</label>
            <b-form-select
              class="select-style"
              v-model="selectTypePrint"
              :options="listTypePrint"
              size="sm"
              value-field="id"
              text-field="name"
            ></b-form-select>
          </b-col>
        </b-row>
        <b-row>
          <b-form-group class="col-md-12 mt-4 pr-0">
            <label>Tìm sản phẩm:</label>
            <b-col md="12" class="row pr-0">
              <b-col md="6" class="p-0">
                <Autosuggest
                  :model="searchProduct"
                  :suggestions="filteredOptionsProduct"
                  :placeholder="'tên, mã, mã vạch sản phẩm'"
                  :limit="10"
                  @select="onSelectedProduct"
                  @change="onInputChangeProduct"
                  suggestionName="productName"
                />
              </b-col>
              <b-col md="2" class="p-0">
                <b-form-select
                  class="select-style"
                  v-model="selectTypeSearch"
                  :options="listTypeSearch"
                  size="sm"
                  value-field="id"
                  text-field="name"
                  @change="debounceInput"
                ></b-form-select>
              </b-col>
              <b-col md="2" class="p-0">
                <b-form-select
                  class="select-style"
                  v-model="selectTypeOfProduct"
                  :options="listTypeOfProduct"
                  size="sm"
                  value-field="id"
                  text-field="name"
                  @change="debounceInput"
                ></b-form-select>
              </b-col>
              <b-col md="2" class="pr-0">
                <div class="d-flex justify-content-end">
                  <button
                    type="button"
                    class="btn btn-secondary font-weight-bolder btn-sm"
                    @click="showModalImportProduct"
                  >
                    <i style="font-size: 1rem" class="far fa-file-excel"></i
                    >Nhập SP từ excel
                  </button>
                </div>
              </b-col>
            </b-col>
          </b-form-group>
        </b-row>
        <b-table
          bordered
          hover
          :fields="fields"
          :items="items"
          v-if="renderComponent"
        >
          <template v-slot:cell(quantity)="row">
            <b-input
              type="text"
              size="sm"
              class="input-style text-right"
              v-model="row.item.quantity"
              @change="forceRerender"
            ></b-input>
          </template>
          <template v-slot:cell(actions)="row">
            <v-icon
              small
              class="text-danger"
              @click="deleteItemInArray(row.item.id)"
              v-b-tooltip
              title="Xóa"
              >mdi-delete</v-icon
            >
          </template>
        </b-table>
        <b-modal
          ref="import-product-modal"
          hide-footer
          title="Thêm sản phẩm từ Excel"
          id="modal-prevent-closing"
          size="lg"
        >
          <b-row>
            <b-col>
              <div class="form-group">
                <span>
                  <b>Bước 1:</b> Vui lòng chuẩn bị dữ liệu mẫu từ file excel
                  đúng format, hoặc có thể tải ở
                  <a :href="urlExcel">đây</a>
                </span>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <span> <b>Bước 2:</b> Tiến hành Import </span>
              <div style="padding-top: 15px">
                <b-form-file
                  placeholder="Hãy chọn một tập tin"
                  @change="selectFile"
                  v-model="file"
                  ref="file-input"
                  class="mb-2"
                ></b-form-file>
              </div>
            </b-col>
          </b-row>
          <b-container class="mt-4 pl-0 pr-0" v-show="importItems.length > 0">
            <h5>
              Danh sách sản phẩm từ file excel( Tổng:
              {{ importItems.length }} sản phẩm)
            </h5>
            <b-table
              id="my-table"
              class="myTable"
              responsive
              bordered
              hover
              :fields="fieldImport"
              :items="importItems"
              :per-page="10"
              :current-page="currentPage"
            >
            </b-table>
            <b-pagination
              v-show="importItems.length > 10"
              v-model="currentPage"
              :total-rows="importItems.length"
              :per-page="10"
              aria-controls="my-table"
            ></b-pagination>
          </b-container>
          <b-button
            style="fontweight: 600; width: 70px"
            variant="primary"
            size="sm"
            @click="validData"
            >Lưu</b-button
          >
          <b-button
            style="margin-left: 10px; font-weight: 600; width: 70px"
            variant="secondary"
            size="sm"
            @click="hideModalImportProduct"
            >Hủy</b-button
          >
        </b-modal>
      </template>
    </KTCodePreview>
  </div>
</template>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import { TIME_TRIGGER, URL_IMPORT_PRODUCT_PRINT } from '@/utils/constants';
import ApiService from '@/core/services/api.service';
import decounce from 'debounce';
import { makeToastFaile, convertPrice, makeToastSuccess } from '@/utils/common';
import xlsx from 'xlsx';

import Autosuggest from '@/view/base/auto-sugguest/AutoSuggest.vue';
export default {
  data() {
    return {
      listTypeSearch: [
        {
          id: 1,
          name: 'Sản phẩm',
        },
        {
          id: 2,
          name: 'Sản phẩm IMEI',
        },
        {
          id: 3,
          name: 'Sản phẩm combo',
        },
        {
          id: 4,
          name: 'Sản phẩm dịch vụ',
        },
      ],
      selectTypeSearch: 1,
      listTypeOfProduct: [
        {
          id: null,
          name: 'Tất cả sản phẩm',
        },
        {
          id: 1,
          name: 'Sản phẩm cha',
        },
        {
          id: 2,
          name: 'Sản phẩm con',
        },
        {
          id: 3,
          name: 'Sản phẩm độc lập',
        },
        {
          id: 4,
          name: 'Sản phẩm cha + độc lập',
        },
        {
          id: 5,
          name: 'Sản phẩm con + độc lập',
        },
      ],
      selectTypeOfProduct: 5,
      searchProduct: '',
      filteredOptionsProduct: [],
      optionsProduct: [
        {
          data: [],
        },
      ],
      isSearching: false,
      fields: [
        {
          key: 'barCode',
          label: 'Mã vạch',
          thStyle: { fontWeight: 600, color: '#181c32', width: '15%' },
          tdClass: 'text-left',
        },
        {
          key: 'productCode',
          label: 'Mã sản phẩm',
          thStyle: { fontWeight: 600, color: '#181c32', width: '15%' },
          tdClass: 'text-left',
        },
        {
          key: 'productName',
          label: 'Tên sản phẩm',
          thStyle: { fontWeight: 600, color: '#181c32', width: '35%' },
          tdClass: 'text-left',
        },
        {
          key: 'listedPrice',
          label: 'Giá',
          thStyle: { fontWeight: 600, color: '#181c32', width: '15%' },
          tdClass: 'text-right',
          formatter: (value) => {
            return convertPrice(value);
          },
        },
        {
          key: 'quantity',
          label: 'SL',
          thStyle: { fontWeight: 600, color: '#181c32', width: '15%' },
          tdClass: 'text-right',
        },
        { key: 'actions', label: '', tdClass: 'text-center' },
      ],
      fieldImport: [
        {
          key: 'productCode',
          label: 'Mã sản phẩm',
          thStyle: { fontWeight: 600, color: '#181c32', width: '35%' },
          tdClass: 'text-left',
        },
        {
          key: 'productName',
          label: 'Tên sản phẩm',
          thStyle: { fontWeight: 600, color: '#181c32', width: '55%' },
          tdClass: 'text-left',
        },
        {
          key: 'quantity',
          label: 'Số lượng',
          thStyle: { fontWeight: 600, color: '#181c32', width: '10%' },
          tdClass: 'text-right',
        },
      ],
      renderComponent: true,
      items: [],
      selectTypePrint: 1,
      listTypePrint: [
        {
          id: 1,
          name: 'Mã vạch 3 tem',
        },
        {
          id: 2,
          name: 'Mã vạch 2 tem',
        },
        {
          id: 3,
          name: 'Bảng giá',
        },
      ],
      importItems: [],
      file: null,
      excellist: [],
      urlExcel: URL_IMPORT_PRODUCT_PRINT,
      currentPage: 1,
    };
  },
  components: {
    KTCodePreview,
    Autosuggest,
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Sản phẩm', route: '/products' },
      { title: 'In sản phẩm' },
    ]);
  },
  methods: {
    forceRerender() {
      this.renderComponent = false;

      this.$nextTick(() => {
        this.renderComponent = true;
      });
    },
    onSelectedProduct(option) {
      const existed = this.items.find((item) => item.id === option.item.id);

      if (existed) {
        return makeToastFaile('Sản phẩm đã được chọn!');
      }
      const productItem = {
        id: option.item.id,
        barCode: option.item.barCode,
        productCode: option.item.productCode,
        productName: option.item.productName,
        listedPrice: option.item.listedPrice,
        sellingPrice: option.item.sellingPrice,
        quantity: 1,
      };

      this.items.push(productItem);

      this.searchProduct = '';
    },
    onInputChangeProduct(text) {
      if (!text) {
        text = '';
      }
      this.searchProduct = text;

      this.debounceInput();
    },
    debounceInput: decounce(function () {
      if (!this.isSearching) {
        const textSearch = this.searchProduct;
        this.fetchProduct(textSearch);
      }
    }, TIME_TRIGGER),
    fetchProduct(textSearch) {
      this.isSearching = true;
      this.optionsProduct[0].data = [];
      ApiService.get(
        `print-product/search?textSearch=${textSearch}&productType=${this.selectTypeSearch}&typeOfProduct=${this.selectTypeOfProduct}`,
      ).then(({ data }) => {
        const products = data.data;
        products.map((element) => {
          this.optionsProduct[0].data.push(element);
        });
        this.filteredOptionsProduct = [...this.optionsProduct[0].data];

        this.isSearching = false;
        if (textSearch !== this.searchProduct) {
          const nextTextSearch = this.searchProduct;
          this.fetchProduct(nextTextSearch);
        }
      });
    },
    deleteItemInArray(productId) {
      for (var i = 0; i < this.items.length; i++) {
        if (this.items[i].id === productId) {
          this.items.splice(i, 1);
        }
      }
    },
    printData() {
      if (!this.items || !this.items.length) {
        return makeToastFaile('Vui lòng chọn sản phẩm cần in!');
      }

      const data = {
        listItem: this.items,
        type: this.selectTypePrint,
      };
      const TYPE_PRINT_PRICE_PRODUCT = 3
      const nameRouter = this.selectTypePrint == TYPE_PRINT_PRICE_PRODUCT ?  'print-price-product' :'print-product-qrcode' ;

      this.$router.push({
        name: nameRouter,
        params: {
          data: data,
        },
      });
    },
    selectFile(event) {
      this.importItems = [];
      const files = event.target.files[0];
      this.file = files;
      if (!files) {
        return;
      } else if (!/\.(xls|xlsx)$/.test(files.name.toLowerCase())) {
        this.file = null;
        this.clearFiles();
        return alert(
          'Định dạng của file tải lên không chính xác. Vui lòng tải lên file có đuôi .xls hoặc .xlsx',
        );
      }
      const fileReader = new FileReader();
      fileReader.onload = (ev) => {
        try {
          const data = ev.target.result;
          const XLSX = xlsx;
          const workbook = XLSX.read(data, {
            type: 'binary',
          });
          const wsname = workbook.SheetNames[0]; // Take the first sheet，wb.SheetNames[0] :Take the name of the first sheet in the sheets
          const ws = XLSX.utils.sheet_to_json(workbook.Sheets[wsname], {
            blankRows: false,
          }); // Generate JSON table content，wb.Sheets Get the data of the first sheet
          const excellist = []; // Clear received data
          // Edit data
          for (var i = 0; i < ws.length; i++) {
            excellist.push(ws[i]);
          } // At this point, you get an array containing objects that need to be processed
          this.excellist = excellist;
          this.excellist.map((element) => {
            const productItem = {
              productCode: element['Mã sản phẩm'] ? element['Mã sản phẩm'] : '',
              productName: element['Tên sản phẩm']
                ? element['Tên sản phẩm']
                : '',
              quantity: element['Số lượng'] ? element['Số lượng'] : 0,
            };
            this.importItems.push(productItem);
          });
        } catch (e) {
          return alert('Read failure!');
        }
      };
      fileReader.readAsBinaryString(files);
    },
    clearFiles() {
      this.$refs['file-input'].reset();
    },
    showModalImportProduct() {
      this.importItems = [];
      this.excellist = [];
      this.$refs['import-product-modal'].show();
    },
    hideModalImportProduct() {
      this.$refs['import-product-modal'].hide();
    },
    validData() {
      const data = {
        listProduct: this.importItems,
      };
      ApiService.post('print-product/import-product-print', data)
        .then((response) => {
          const { status, message } = response.data;
          if (status === 1) {
            makeToastSuccess(message);
            response.data.data.forEach((element) => {
              this.items.push(element);
            });
            this.hideModalImportProduct();
          } else {
            makeToastFaile(message);
          }
        })
        .catch((err) => {
          const message = err;
          makeToastFaile(message);
        });
    },
  },
};
</script>

<style lang="scss">
.print-product {
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: middle;
    border-top: 1px solid #ecf0f3;
  }
  .table.b-table.table-sm
    > thead
    > tr
    > [aria-sort]:not(.b-table-sort-icon-left),
  .table.b-table.table-sm
    > tfoot
    > tr
    > [aria-sort]:not(.b-table-sort-icon-left) {
    background-position: right calc(1.5rem / 2) center;
    padding-right: calc(0.3rem + 0.65em);
  }
  /* custom scrollbar */
  ::-webkit-scrollbar {
    width: 13px;
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #d6dee1;
    border-radius: 20px;
    border: 3px solid transparent;
    background-clip: content-box;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #a8bbbf;
  }
}
</style>
